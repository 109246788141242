<template>
  <v-container fluid>
    <v-card>
      <v-toolbar
        color="secondary"
        flat
        dense
      >
        <v-toolbar-title>Document Approvals</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            sm="12"
            md="12"
          >
            <v-data-table
              :headers="headers"
              :items-per-page="itemsPerPage"
              :items="numberingData"
              :search="search"
            >

              <template v-slot:item.approval="{ item }">
                <v-btn
                  class="btnLink"
                  color="accent"
                  text
                  @click="changeStatus(item)"
                >{{ item.approval }}</v-btn>
              </template>

              <!-- end -->
            </v-data-table>
          </v-col>
        </v-row>
        <snackbar ref="snackbar"></snackbar>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    search: "",
    loading: false,
    itemsPerPage: 100,
    headers: [
      { text: "Document", value: "DocumentName" },
      { text: "External Approval.", value: "approval" },
    ],
    numberingData: [],
  }),
  methods: {
    getData() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/doc_model?isDoc=1`)
        .then((res) => {
          self.numberingData = res;
          self.loading = false;
        })
        .catch((err) => {
          console.log(err, "error");
        });
    },
    changeStatus(data) {
      const url = `/doc_model/${data.ObjectID}`;
      this.$store
        .dispatch("put", { url, data })
        .then(() => {
          this.getData();
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getData();
  },
};
</script>